body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html { 
  background: url(images/peppers.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.backgroundoverlay {
  /* default fallback */
  background: rgb(255, 255, 255) transparent;
  /* nice browsers */
  background: rgba(255, 255, 255, 0.8);
  /* IE 6/7 */
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#CCFFFFFF, endColorstr=#CCFFFFFF);
  /* IE8 */    
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#CCFFFFFF, endColorstr=#CCFFFFFF)";
}
